import React, { Component } from "react";
import {
  FiActivity,
  FiUserCheck,
  FiType,
  FiFileText,
  FiDollarSign,
  FiCode,
  FiBox,
  FiCpu,
} from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiUserCheck />,
    title: "Understanding Humans",
    description:
      "Neural networks for recognizing other people’s actions, which can understand the meaning of both text and voice messages or predict the further behavior of humans based on their actions.",
  },
  {
    icon: <FiType />,
    title: "Creating Content",
    description:
      "Generative neural networks help come up with an anecdote, a comment on an article, or the article itself for your blog. Smart algorithms control the bots’ behavior and make it as close as possible to the behavior of humans.",
  },
  {
    icon: <FiCpu />,
    title: "Imitating a Human",
    description:
      "Each bot uses the same software as real people. For instance, when visiting a website, a bot uses a real browser and emulates mouse movements, pauses, and clicks on the page, switching between a phone and a personal computer.",
  },
  {
    icon: <FiBox />,
    title: "Complete Ecosystem",
    description:
      "An exclusive ecosystem for work is selected depending on the individual features. For example, if the set country is Brazil, the bot will access the Internet with a unique IP address from Brazil.",
  },
  {
    icon: <FiCode />,
    title: "Fine Tuning",
    description:
      "If standard modules are not enough, you can add unique solutions by using low-code tools. You can write any script and schedule time for its execution. For instance, you can write a script for congratulating your friends on their birthdays or a script for invoicing your partners.",
  },
  {
    icon: <FiActivity />,
    title: "Background Operation",
    description:
      "When you need its assistance, the bot lives its own life. It can develop its social networks or blog, comment on content created by someone else, or generate one on its own. Due to this fact, for example, the likes our bots drop under your photo on Instagram do not arouse suspicion.",
  },
  {
    icon: <FiFileText />,
    title: "Personal Card",
    description:
      "Each bot has its own profile in the system, which means that wherever it goes, it uses the same details and creates its own network of accounts with the same name and photos. The frequency of visiting social networks or creating content is regulated depending on the individual features.",
  },
  {
    icon: <FiDollarSign />,
    title: "Cost Optimization",
    description:
      "You pay as you use it. You only pay for the time spent to fulfill your task. Bots complete tasks thousands of times faster than humans do. The idle time is not charged. If you use standard modules of the system, you don’t even have to buy bots for personal use.",
  },
];

class ServiceTwo extends Component {
  render() {
    let title = "Key Features",
      description =
        "Bots can decipher Google Captchas and come up with anecdotes — and these are not their coolest features.",
      subtitle = "Bots can go beyond your expectations!";

    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span className="subtitle">{subtitle}</span>
              <h2 className="title">{title}</h2>
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-12 mt--30">
            <div className="row service-main-wrapper">
              {ServiceList.map((val, i) => (
                <div
                  className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="service service__style--2 text-left">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ServiceTwo;
