import React from "react";

import SectionSubtitle from "../SectionSubtitle";
import Title3 from "../Title3";
import Description from "../Description";
import ButtonComingSoon from "../ButtonComingSoon";
import imageUrl from "./image.png";

const RoadmapStepFourth = () => {
  return (
    <div className="row align-items-center py-5 mt-5">
      <div className="col-lg-6 col-md-12 order-2 order-lg-1">
        <SectionSubtitle>Q3 2022</SectionSubtitle>
        <Title3>No-Code new generation bot builder</Title3>
        <Description>
          <p className="mb-2">
            A full-fledged bot builder that helps automate any business tasks.
          </p>
          <p className="mb-2">
            Full emulation of human actions with the help of smart algorithms
            and neural networks.
          </p>
        </Description>
        <div className="button-group mt-5">
          <ButtonComingSoon disabled>Coming soon</ButtonComingSoon>
        </div>
      </div>

      <div className="col-lg-5 offset-lg-1 col-md-12 order-1 order-lg-2">
        <img className="w-100" src={imageUrl} alt="Step Fourth Images" />
      </div>
    </div>
  );
};

export default React.memo(RoadmapStepFourth);
