import React from "react";

import SectionSubtitle from "../SectionSubtitle";
import Title3 from "../Title3";
import Description from "../Description";
import imageUrl from "./image.png";

const RoadmapStepFirst = () => {
  return (
    <div className="row align-items-center py-5 mt-5">
      <div className="col-lg-5 col-md-12">
        <img className="w-100" src={imageUrl} alt="Step One Images" />
      </div>

      <div className="col-lg-6 offset-lg-1 col-md-12">
        <SectionSubtitle>Q4 2021</SectionSubtitle>
        <Title3>Safe bots to automate your NFT game accounts</Title3>
        <Description>
          <p className="mb-2">
            Ready bots for most popular games such as FarmerWorld, Xpansion,
            NftPanda and many others. The service has no monthly subscription
            and works for only 10% of your profit.
          </p>
          <ul>
            <li>They work around the clock, do not allow downtime.</li>
            <li>Provide the maximum profit.</li>
            <li>Completely emulate human actions.</li>
          </ul>
        </Description>
        <div className="button-group mt-5">
          <a
            className="btn-default size-md keychainify-checked"
            href="https://games.smartbot.black/"
          >
            Get Started Now
          </a>
          <a
            className="btn-transparent keychainify-checked"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/smartbotblack"
          >
            Try free bots
          </a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RoadmapStepFirst);
