import React from "react";

import SectionSubtitle from "../SectionSubtitle";
import Title3 from "../Title3";
import Description from "../Description";
import ButtonComingSoon from "../ButtonComingSoon";
import imageUrl from "./image.png";

const RoadmapStepSecond = () => {
  return (
    <div className="row align-items-center py-5  mt-5">
      <div className="col-lg-6 col-md-12 order-2 order-lg-1">
        <SectionSubtitle>Q1 2022</SectionSubtitle>
        <Title3>Bots for buying, selling and monitoring NFTs</Title3>
        <Description>
          <p className="mb-2">
            Bots help you buy NFT the fastest, sell at the right time, and
            monitor market activity.
          </p>
          <p className="mb-2">
            Bots work with all popular marketplaces, including binance and
            atomichub.
          </p>
        </Description>
        <div className="button-group mt-5">
          <ButtonComingSoon disabled>Coming soon</ButtonComingSoon>
        </div>
      </div>

      <div className="col-lg-5 offset-lg-1 col-md-12 order-1 order-lg-2">
        <img className="w-100" src={imageUrl} alt="Step Second Images" />
      </div>
    </div>
  );
};

export default React.memo(RoadmapStepSecond);
