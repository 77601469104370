import React, { Component } from "react";
import { FiZap, FiUsers, FiCode } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiZap />,
    title: "Automation of Any Tasks",
    description:
      "Your personal assistants will respond to your emails, create and publish an article in your corporate blog, introduce your company in a FB chat, increase the number of views on YouTube, or drop likes on Instagram.",
  },
  {
    icon: <FiUsers />,
    title: "Emulation of Human Actions",
    description:
      "Each bot is individual; neural networks regulate its behavior which depends on unique features. Bots use the same programs as real people. Modern systems do not distinguish them from humans.",
  },
  {
    icon: <FiCode />,
    title: "No-Code / Low-Code",
    description:
      "A simple and intuitive interface ensures the easy creation of sophisticated multifunctional bots, even with no programming skills required. However, you can write your own script, if necessary.",
  },
];
class ServiceOne extends Component {
  render() {
    return (
      <>
        <div className="row row--25">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-12 col-sm-12 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}
export default ServiceOne;
