import styled from "@emotion/styled";

import ButtonDefault from "./ButtonDefault";

const ButtonComingSoon = styled(ButtonDefault)`
  background: inherit;
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  color: #fff;
`;

export default ButtonComingSoon;
