import React from "react";
import ScrollToTop from "react-scroll-up";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Particles from "react-particles-js";
import {
  FiClock,
  FiUserCheck,
  FiCheckSquare,
  FiBell,
  FiX,
  FiCheck,
  FiChevronUp,
} from "react-icons/fi";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

import Helmet from "./component/common/Helmet";

const particlesParams = {
  particles: {
    number: {
      value: 60,
      density: {
        enable: true,
        value_area: 1500,
      },
      size: {
        value: 5,
      },
    },
    line_linked: {
      enable: true,
      opacity: 0.05,
    },
    move: {
      speed: 0.1,
    },
    size: {
      value: 1,
    },
    opacity: {
      anim: {
        enable: true,
        speed: 1,
        opacity_min: 0.05,
      },
    },
  },
  interactivity: {
    events: {
      onclick: {
        enable: true,
        mode: "push",
      },
    },
    modes: {
      push: {
        particles_nb: 1,
      },
    },
  },
  retina_detect: true,
};

const NFT = () => {
  const [didViewCountUp, setDidViewCountUp] = React.useState(false);
  const onVisibilityChange = React.useCallback((isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  }, []);

  return (
    <div className="active-dark">
      <Helmet pageTitle="" />

      <header
        className={`header-area header--fixed formobile-menu header--transparent default-color`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">Smart Bot</a>
            </div>
          </div>
          {/* <div className="header-right">
            <div className="header-btn">
              <a
                className="btn-default btn-border btn-opacity"
                onClick={open}
                href="#getEarlyAccess"
              >
                <span>Войти</span>
              </a>
            </div>
          </div> */}
        </div>
      </header>

      {/* Start Slider Area   */}
      <div className="slider-wrapper slider-creative-agency with-particles nft-slider">
        <Particles
          params={particlesParams}
          style={{ position: "absolute", height: "100%" }}
        />
        <div className="slider-activation pt-5 pb-5">
          {/* Start Single Slide */}
          <div
            className="slide slide-style-1 d-flex flex-column align-items-center justify-content-center bg_image bg_image--1 pt-5 pb-5"
            data-black-overlay="8"
          >
            <div className="container position-relative text-center pt-5 pb-5">
              <h1 className="theme-gradient">
                Получи максимальный доход от своих аккаунтов
              </h1>

              {/* Start Service Area */}
              <div className="service-wrapper service-white plr--120">
                <div className="row row--25">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="service service__style--1">
                      <div className="icon-image">
                        <img
                          src={`/assets/images/nft/pvu.png`}
                          alt="Plants vs Undead"
                        />
                      </div>
                      <div className="content pt-3">
                        <h4 className="title">Plants vs Undead</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="service service__style--1">
                      <div className="icon-image">
                        <img
                          src={`/assets/images/nft/tlm.png`}
                          alt="Plants vs Undead"
                        />
                      </div>
                      <div className="content pt-3">
                        <h4 className="title">Alien Worlds</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="service service__style--1">
                      <div className="icon-image">
                        <img
                          src={`/assets/images/nft/farmers-world.png`}
                          alt="Plants vs Undead"
                        />
                      </div>
                      <div className="content pt-3">
                        <h4 className="title">Farmers World</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Service Area */}
              <div class="slide-btn pt--120">
                <a
                  class="btn-default btn-border btn-opacity"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://t.me/voodee"
                >
                  Купить бота
                </a>
              </div>
              {/* <p className="theme-gradient">с помощью безопасных ботов</p> */}
            </div>
          </div>
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start Service Area  */}
      <div className="service-area bg_color--5 pb--80">
        <div className="wrapper plr--120">
          <div className="row">
            <div className="col-lg-12 col-12 mt--30">
              <div className="row service-main-wrapper">
                <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12">
                  <div className="service service__style--2 text-center">
                    <div className="icon">
                      <FiClock />
                    </div>
                    <div className="content">
                      <h3 className="title">Круглосуточно автономная работа</h3>
                      {/* <p>{val.description}</p> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12">
                  <div className="service service__style--2 text-center">
                    <div className="icon">
                      <FiUserCheck />
                    </div>
                    <div className="content">
                      <h3 className="title">
                        Полная эмуляция действий человека
                      </h3>
                      {/* <p>{val.description}</p> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12">
                  <div className="service service__style--2 text-center">
                    <div className="icon">
                      <FiCheckSquare />
                    </div>
                    <div className="content">
                      <h3 className="title">
                        Автоматическое распознавание капчи
                      </h3>
                      {/* <p>{val.description}</p> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12">
                  <div className="service service__style--2 text-center">
                    <div className="icon">
                      <FiBell />
                    </div>
                    <div className="content">
                      <h3 className="title">
                        Настраиваемые уведомления в телеграм
                      </h3>
                      {/* <p>{val.description}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      <div className="rn-counterup-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div>
                <table className="table">
                  <tr>
                    <th></th>
                    <td className="text-center">
                      <h3>
                        Коробочное
                        <br />
                        решение
                      </h3>
                    </td>
                    <td className="text-center">
                      <h3>
                        Онлайн
                        <br />
                        бот
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3>Plants vs Undead</h3>
                    </td>
                    <td className="text-center">
                      <p>Обновление</p>
                    </td>
                    <td className="text-center">
                      <p>Обновление</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3>Alien Worlds</h3>
                    </td>
                    <td className="text-center">
                      <p>
                        <a
                          className="theme-gradient"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://t.me/voodee"
                        >
                          Купить
                        </a>
                      </p>
                    </td>
                    <td className="text-center">
                      <p>Обновление</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3>Farmers World</h3>
                    </td>
                    <td className="text-center">
                      <p>Обновление</p>
                    </td>
                    <td className="text-center">
                      <p>Обновление</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start CounterUp Area */}
      <div className="rn-counterup-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle">Наша статистика</span>
                <h2 className="title">Количество работающих ботов</h2>
              </div>
            </div>
          </div>
          <div className="row mt--30">
            <div className="im_single_counterup col-sm-4 col-12">
              <div className="im_counterup">
                <div className="inner">
                  <h2 className="counter not-plus">
                    <VisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp end={didViewCountUp ? 14 : 0} />
                    </VisibilitySensor>
                  </h2>
                  <p className="description">Plants vs Undead</p>
                </div>
              </div>
            </div>

            <div className="im_single_counterup col-sm-4 col-12">
              <div className="im_counterup">
                <div className="inner">
                  <h2 className="counter not-plus">
                    <VisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp end={didViewCountUp ? 8 : 0} />
                    </VisibilitySensor>
                  </h2>
                  <p className="description">Alien Worlds</p>
                </div>
              </div>
            </div>

            <div className="im_single_counterup col-sm-4 col-12">
              <div className="im_counterup">
                <div className="inner">
                  <h2 className="counter not-plus">
                    <VisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp end={didViewCountUp ? 0 : 0} />
                    </VisibilitySensor>
                  </h2>
                  <p className="description">Farmers World</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End CounterUp Area */}

      <div className="rn-counterup-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <table className="table">
                <tr>
                  <th></th>
                  <td className="text-center">
                    <h3>
                      Коробочное
                      <br />
                      решение
                    </h3>
                  </td>
                  <td className="text-center">
                    <h3>
                      Онлайн
                      <br />
                      бот
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Работает, когда ваш компьютер выключен</h4>
                  </td>
                  <td className="icon-table--red">
                    <FiX />
                  </td>
                  <td className="icon-table--green">
                    <FiCheck />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Не использует ресурсы вашего компьютера</h4>
                  </td>
                  <td className="icon-table--red">
                    <FiX />
                  </td>
                  <td className="icon-table--green">
                    <FiCheck />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Автоматически подбирает индивидуальные прокси</h4>
                  </td>
                  <td className="icon-table--red">
                    <FiX />
                  </td>
                  <td className="icon-table--green">
                    <FiCheck />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>Ваши данные храняться только на вашем компьютере</h4>
                  </td>
                  <td className="icon-table--green">
                    <FiCheck />
                  </td>
                  <td className="icon-table--red">
                    <FiX />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Start Faq Area */}
      <div id="faq" className="pv-feaq-area bg_color--5 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-title text-center pb--30">
                <span className="subtitle theme-gradient">
                  Список популярных вопросов
                </span>
                <h2 className="title">Популярные вопросы</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="faq-area">
                <Accordion className="accodion-style--1" preExpanded={"0"}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Как работают боты?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Бот использует обычный браузер с индивидуальным
                        отпечатком под каждый аккаунт и полностью эмулирует
                        нажатие клавиш мыши по нужным кнопкам.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Мой аккаунт не заблокируют?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Боты максимально точно эмулируют действия человека, в
                        том числе движение мыши и задержки кликов. Боты
                        используют те же браузеры, что и обычные люди. Для
                        каждого бота используется индивидуальный качественный
                        прокси. Вероятность бана с нашей стороны минимальна.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Сколько я смогу заработать с помощью бота?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Всё зависит от конкретной игры и ваших первоначальных
                        вложений. В любом случае стоимость покупки бота
                        окупается менее, чем за месяц.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Как получить доступ к боту?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Написать на почту{" "}
                        <a
                          href="mailto:hello@smartbot.black"
                          class="theme-gradient"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          hello@smartbot.black
                        </a>{" "}
                        или в телегам{" "}
                        <a
                          href="https://t.me/voodee"
                          class="theme-gradient"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          @voodee
                        </a>
                        .
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Start Faq Area */}

      {/* Start Footer Area  */}
      <footer
        id="footer"
        className="pv-callto-action call-to-action-wrapper text-white-wrapper footer-style-01 ptb--120"
        data-black-overlay="9"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <h4>По вопросам приобретения обращайтесь:</h4>
                <div className="footer-link mt--35">
                  <ul class="ft-link">
                    <li>
                      Email:{" "}
                      <a
                        href="mailto:hello@smartbot.black"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        hello@smartbot.black
                      </a>
                    </li>
                    <li>
                      Telegram:{" "}
                      <a
                        href="https://t.me/voodee"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        @voodee
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer Area  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default NFT;
