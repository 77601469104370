import styled from "@emotion/styled";

const ButtonDefault = styled.button`
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 16px 40px;
  border-radius: 500px;
  display: inline-block;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  background-size: 152% 100%;
  background: #fd4766;
  border: 2px solid #fd4766;
`;

export default ButtonDefault;
