import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Particles from "react-particles-js";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import { Link } from "react-scroll";

import Header from "./component/header/Header";

import SliderOne from "./component/slider/SliderOne";
import ServiceTwo from "./elements/service/ServiceTwo";
import CounterOne from "./elements/counters/CounterOne";
import About from "./component/HomeLayout/homeOne/About";
import BrandTwo from "./elements/BrandTwo";
import Helmet from "./component/common/Helmet";

import Roadmap from "./Roadmap";

const app = initializeApp({
  apiKey: "AIzaSyAWwZBjd_KrGyj_7oMnArmDp4UclwigCWA",
  authDomain: "ubot-fd07c.firebaseapp.com",
  projectId: "ubot-fd07c",
  storageBucket: "ubot-fd07c.appspot.com",
  messagingSenderId: "894322725608",
  appId: "1:894322725608:web:bf9baead6b7bcf0f61ef5d",
  measurementId: "G-SYNFP9RTZE",
});

if (window.location.hostname !== "localhost") {
  getAnalytics(app);
}

const particlesParams = {
  particles: {
    number: {
      value: 60,
      density: {
        enable: true,
        value_area: 1500,
      },
      size: {
        value: 5,
      },
    },
    line_linked: {
      enable: true,
      opacity: 0.05,
    },
    move: {
      speed: 0.1,
    },
    size: {
      value: 1,
    },
    opacity: {
      anim: {
        enable: true,
        speed: 1,
        opacity_min: 0.05,
      },
    },
  },
  interactivity: {
    events: {
      onclick: {
        enable: true,
        mode: "push",
      },
    },
    modes: {
      push: {
        particles_nb: 1,
      },
    },
  },
  retina_detect: true,
};

const Main = () => {
  return (
    <div className="active-dark">
      <Helmet pageTitle="" />
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Slider Area   */}
      <div className="slider-wrapper slider-creative-agency with-particles">
        <Particles
          params={particlesParams}
          style={{ position: "absolute", height: "100%" }}
        />
        <SliderOne />
      </div>
      {/* End Slider Area   */}

      <Roadmap />

      {/* Start About Area */}
      <div className="about-area ptb--120 bg_color--1">
        <About />
      </div>
      {/* End About Area */}

      {/* Start Service Area  */}
      <div className="service-area ptb--120 bg_color--5">
        <div className="wrapper plr--120">
          <ServiceTwo />
        </div>
      </div>
      {/* End Service Area  */}

      {/* Start CounterUp Area */}
      <div className="rn-counterup-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle">Constant Growth</span>
                <h2 className="title">Our Statistics</h2>
              </div>
            </div>
          </div>
          <CounterOne />
        </div>
      </div>
      {/* End CounterUp Area */}

      {/* Start Faq Area */}
      <div id="faq" className="pv-feaq-area bg_color--5 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-title text-center pb--30">
                <span className="subtitle theme-gradient">
                  Check out our FAQ section to see if we can help
                </span>
                <h2 className="title">Do you have any Question</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="faq-area">
                <Accordion className="accodion-style--1" preExpanded={"0"}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When will I get access to the constructor?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Our release is scheduled for early 2022. By this time,
                        we are issuing invitations to some people to help us
                        test the product.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Do you use your bots yourself?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, we do it very actively. For example, all articles
                        on our blog are written by bots exclusively. Bots also
                        repost our articles on social networks. And for sure,
                        they also drop likes and leave comments on our posts.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        I don’t want to grapple with the constructor, but I need
                        the assistance of bots. Can you help me?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, we can customize a script for you or develop a
                        tailored solution. Please email us at{" "}
                        <a
                          className="theme-gradient"
                          href="mailto:hello@smartbot.black"
                        >
                          hello@smartbot.black
                        </a>
                        .
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Your system doesn’t have the module I need. Can you add
                        it?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, we are ready to help solve your problem. Please
                        describe your problem by emailing us at
                        <a
                          className="theme-gradient"
                          href="mailto:hello@smartbot.black"
                        >
                          hello@smartbot.black
                        </a>
                        .
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Start Faq Area */}

      {/* Start Brand Area */}
      <div className="rn-brand-area bg_color--1 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section-title text-center mb--30">
                <span className="subtitle">They are working with us</span>
                <h2 className="title">Our Partners</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}

      {/* Start Footer Area  */}
      <footer
        className="pv-callto-action call-to-action-wrapper text-white-wrapper call-to-action ptb--120"
        data-black-overlay="9"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <span>Buy your smart personal assistants</span>
                <h2 className="mt--20 theme-gradient font-700">Let's go</h2>
                <div className="footer-btn mt--35">
                  <Link
                    className="btn-default btn-large btn-border btn-opacity"
                    to="roadmap"
                    spy={true}
                    smooth={true}
                    duration={500}
                    href="#"
                  >
                    View RoadMap
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer Area  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default Main;
