import React, { Component } from "react";
import { Link } from "react-scroll";

import logoUrl from "./logo.png";
class Header extends Component {
  render() {
    const { color = "default-color" } = this.props;

    return (
      <header
        className={`header-area header--fixed formobile-menu header--transparent ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <img src={logoUrl} alt="Smart Bot"></img>
            </div>
          </div>
          <div className="header-right">
            <div className="header-btn">
              <Link
                className="btn-default btn-border btn-opacity"
                to="roadmap"
                spy={true}
                smooth={true}
                duration={500}
                href="#"
              >
                View RoadMap
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
