import React from "react";
import { Link } from "react-scroll";

const names = [
  "A****a L*******r",
  "R****t F******a",
  "M*****w C*****s",
  "E***i B****r",
  "S******h O*****n",
  "F****y C*****n",
  "N****e C**o",
  "J***h A*****s",
  "C*m G*****e",
  "D***e O***z",
];

const avatars = [
  "/assets/images/face/1.jpeg",
  "/assets/images/face/2.jpeg",
  "/assets/images/face/3.jpeg",
  "/assets/images/face/4.jpeg",
  "/assets/images/face/5.jpeg",
  "/assets/images/face/6.jpeg",
  "/assets/images/face/7.jpeg",
  "/assets/images/face/8.jpeg",
  "/assets/images/face/9.jpeg",
  "/assets/images/face/10.jpeg",
];

const actions = [
  "Checked new emails",
  "Responded with a thanks to the letter",
  "Responded to a question in the comments of a Facebook post",
  "Sent a notification to Telegram about a new release in Gitlab",
  ...Array(20).fill("Liked the comment on Instagram"),
  ...Array(20).fill("Liked the photo on Instagram"),
  ...Array(5).fill("Took a look at the storis on Instagram"),
  "Got a like of a comment on Instagram",
  "Forwarded the text of an email from Gmail to a Telegram chat",
  "Read the Facebook post and reposted it",
  "Wrote a new post on reddit",
  "Read the post and liked it on reddit",
  "Uploaded a new photo to Instagram",
  "Went to the site through an advertising link",
  "Watched a video on YouTube",
  "Watching a Twitch stream",
  "Visited the website ***.com",
  ...Array(20).fill("Liked the video on YouTube"),
];

let title = "Smart Bots Behave Like Real People",
  description =
    "The bots’ behavior is fully personalized and depends on the individual features that are set when creating a bot: name, gender, age, country, favorite dish, and much more. They are constantly learning and can make decisions based on the history of their actions.",
  description2 =
    "Bots use the same software as real people: Chrome, FB Messenger, or Instagram Client; they emulate mouse movements or screen touches, which makes it virtually impossible to detect the bot.",
  description3 =
    "The bot can become your personal assistant, while completely imitating the actions of real people on the web.";

function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

const generateItem = (date = 1e3) => {
  const peopleNumber = Math.floor(Math.random() * names.length);

  return {
    id: `${Date.now() - date}`,
    name: names[peopleNumber],
    avatar: avatars[peopleNumber],
    date: new Date(Date.now() - date),
    action: actions[Math.floor(Math.random() * actions.length)],
  };
};

const defaultState = [5e3, 14e3, 22e3, 27e3, 35e3, 38e3, 42e3, 49e3].map(
  generateItem
);

const About = () => {
  const [items, setItems] = React.useState(defaultState);

  React.useEffect(() => {
    const intervel = setInterval(() => {
      if (Math.random() > 0.9) {
        setItems((items) => [generateItem(), ...items].slice(0, 50));
      }
    }, 1e3);

    return () => {
      clearInterval(intervel);
    };
  }, []);

  return (
    <div className="about-wrapper">
      <div className="container">
        <div className="row row--35 align-items-center">
          <div className="col-lg-5 col-md-12 order-2 order-lg-1">
            <div className="feed">
              {items.map(({ id, name, avatar, action, date }) => (
                <div className="feed-item" key={id}>
                  <img className="feed-item-avatar" src={avatar} alt={name} />
                  <div className="feed-item-content">
                    <div className="feed-item-content__header">
                      <span>{name}</span> <span>{timeSince(date)} ago</span>
                    </div>
                    <div className="feed-item-content__description">
                      {action}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-7 col-md-12 order-1 order-lg-2">
            <div className="about-inner inner">
              <div className="section-title">
                <h2 className="title">{title}</h2>
                <p className="description">{description}</p>
                <p className="description">{description2}</p>
                <p className="description">{description3}</p>
                <div className="purchase-btn">
                  <Link
                    className="btn-transparent"
                    to="roadmap"
                    spy={true}
                    smooth={true}
                    duration={500}
                    href="#"
                  >
                    View RoadMap
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
