import React from "react";

import SectionSubtitle from "../SectionSubtitle";
import Title3 from "../Title3";
import Description from "../Description";
import ButtonComingSoon from "../ButtonComingSoon";
import imageUrl from "./image.png";

const RoadmapStepThird = () => {
  return (
    <div className="row align-items-center py-5 mt-5">
      <div className="col-lg-5 col-md-12">
        <img className="w-100" src={imageUrl} alt="Step Third Images" />
      </div>

      <div className="col-lg-6 offset-lg-1 col-md-12">
        <SectionSubtitle>Q1 2022</SectionSubtitle>
        <Title3>SMM Panel / Gleam bots / Bots for messengers</Title3>
        <Description>
          <p className="mb-2">
            Bots that help maintain activity and emulate various actions in
            social networks and messengers.
          </p>
          <p className="mb-2">
            Telegram, Discord, Twitter, etc. Over 100+ Integrations.
          </p>
        </Description>
        <div className="button-group mt-5">
          <ButtonComingSoon disabled>Coming soon</ButtonComingSoon>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RoadmapStepThird);
