import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { FiSmile, FiTrendingUp, FiDollarSign } from "react-icons/fi";

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    let Data = [
      {
        icon: <FiSmile />,
        countNum: 600,
        countTitle: "Active bots",
      },
      {
        icon: <FiTrendingUp />,
        countNum: 150000,
        countTitle: "Completed actions",
      },
      {
        icon: <FiDollarSign />,
        countNum: 25000,
        countTitle: "Helped earn",
      },
    ];

    return (
      <>
        <div className="row mt--30">
          {Data.map((value, index) => (
            <div className="im_single_counterup col-sm-4 col-12" key={index}>
              <div className="im_counterup">
                <div className="inner">
                  <div className="icon">{value.icon}</div>
                  <h2 className="counter">
                    <VisibilitySensor
                      onChange={this.onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp
                        end={this.state.didViewCountUp ? value.countNum : 0}
                      />
                    </VisibilitySensor>
                  </h2>
                  <p className="description">{value.countTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}
export default CounterOne;
