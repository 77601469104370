import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <ul className="brand-style-2">
        <li>
          <a
            href="https://fasie.ru/"
            title="FASIE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/brand/fasie_logo.png" alt="FASIE" />
          </a>
        </li>
        <li>
          <a
            href="https://firon.org/"
            title="Firon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/brand/firon.png" alt="Firon" />
          </a>
        </li>
        <li>
          <a
            href="https://south-itpark.ru/"
            title="IT_Park"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/brand/itpark.png" alt="IT_Park" />
          </a>
        </li>
      </ul>
    );
  }
}
export default BrandTwo;
