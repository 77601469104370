import React from "react";
import styled from "@emotion/styled";

import RoadmapStepFirst from "./RoadmapStepFirst";
import RoadmapStepSecond from "./RoadmapStepSecond";
import RoadmapStepThird from "./RoadmapStepThird";
import RoadmapStepFourth from "./RoadmapStepFourth";
import SectionSubtitle from "./SectionSubtitle";

const Wrapper = styled.div`
  background: #151515;
`;

const SectionTitle = styled.h2`
  font-size: 50px;
  font-weight: 600;
  line-height: 62px;
  margin-bottom: 20px;
`;

const Roadmap = () => {
  return (
    <Wrapper className="ptb--120" id="roadmap">
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-12">
            <div className="text-center">
              <SectionSubtitle>Growth Plan</SectionSubtitle>
              <SectionTitle>Roadmap</SectionTitle>
            </div>
          </div>
        </div>

        <RoadmapStepFirst />

        <RoadmapStepSecond />

        <RoadmapStepThird />

        <RoadmapStepFourth />
      </div>
    </Wrapper>
  );
};

export default React.memo(Roadmap);
