import styled from "@emotion/styled";

const SectionSubtitle = styled.span`
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;

  background: #fd4766;
  background: linear-gradient(
    120deg,
    #1c99fe 20.69%,
    #7644ff 50.19%,
    #fd4766 79.69%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#FD4766", endColorstr="#337DFF",GradientType=1 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
`;

export default SectionSubtitle;
